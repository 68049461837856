<template>
  <div
    id="world-map-container"
    class="map-container"
    :class="isMobile ? 'island' + softSkillIdx : 'bg_screen'"
  >
    <div class="flex-container world-background flex flex-wrap">
      <Island
        v-for="(island, index) in islands"
        :key="island.name"
        class="item"
        :class="`island-order-${index+1}`"
        :name="island.name"
        :status="(softSkillIdx > index) ? 'completed' : 'pending'"
      />

      <div class="item island-vortex island-order-placeholder" />
    </div>

    <div
      v-if="!isMobile"
      id="ship"
      class="piece infinite tossing"
      :class="{
        'animated': ship.animated,
        'backwards': ship.backwards
      }"
      :style="{
        top:ship.top + '%',
        left:ship.left + '%'
      }"
    />

    <div class="clouds">
      <div
        v-for="cloud in clouds"
        :key="cloud.id"
        class="cloud"
        :class="['cloud' + cloud.num, cloud.extraClass]"
      />
    </div>

    <div class="dashes">
      <div
        v-for="dash in dashes"
        v-show="dash.visible"
        :key="dash.id"
        class="dash"
        :class="[dash.type, dash.backwards ? 'backwards' : '', dash.extraClass]"
        :style="{
          top:dash.top + '%',
          left:dash.left + '%',
          '-ms-transform': 'rotate(' + dash.rotate + 'deg)',
          '-webkit-transform': 'rotate(' + dash.rotate + 'deg)',
          transform: 'rotate(' + dash.rotate + 'deg)'
        }"
      />
    </div>
  </div>
</template>

<script>
import Island from '@/components/Island'

export default {
  name: 'WorldMap',
  components: {
    Island
  },
  data() {
    return {
      isMobile: false,
      ship: {
        backwards: false,
        animated: true
      },
      header: this.$parent.$refs.header,
      clouds: [],
      cloudsPerIsland: 7
    }
  },
  computed: {
    islands() {
      return this.$store.getters.islands
    },

    dashes() {
      return require('@/config/worldMapDashes')
    },

    dashesDB() {
      return TAFFY(this.dashes)
    },

    shipPositions() {
      return {
        points: [
          { left: '-1', top: '-4' },
          { left: '18.8', top: '10' },
          { left: '55', top: '10' },
          { left: '77.2', top: '19.7' },
          { left: '77.6', top: '48.8' },
          { left: '75.6', top: '82' },
          { left: '40.4', top: '78.4' },
          { left: '10.6', top: '75.2' },
          { left: '21.6', top: '35.2' },
          { left: '40.5', top: '48.2' },
          { left: '48.6', top: '52.4' }
        ]
      }
    },

    currentQuestion() {
      return this.$store.getters.gameplay.gameplayProfile.currentQuestion
    }

  },
  mounted() {
    this.header.allowMuteNarration = false
    this.header.allowFontEdit = false

    this.resetNarrationSound()

    const shipPosition = this.shipPositions.points[this.softSkillIdx]
    this.ship.top = shipPosition.top
    this.ship.left = shipPosition.left

    let cloudsStart = 0
    if (this.currentQuestion > 0 || this.softSkillIdx > 0) {
      cloudsStart = (this.softSkillIdx + 1) * this.cloudsPerIsland + 1
    }

    for (
      let ci = cloudsStart;
      ci < (this.islands.length + 1) * this.cloudsPerIsland;
      ci++
    ) {
      this.clouds.push({ num: ci, visible: !this.isMobile, extraClass: '' })
    }

    if (window.innerWidth <= 480) {
      this.isMobile = true
      const cloudNum = this.softSkillIdx * this.cloudsPerIsland + 1
      this.clouds[cloudNum].visible = true
      this.clouds[cloudNum].extraClass = 'animated bounceOut'
      this.endMapScreen(2750)
    }
    else {
      Object.entries(this.dashes).forEach(([i, dash]) => {
        if (dash.skill < this.softSkillIdx) {
          dash.visible = true
          dash.extraClass = ''
        }
      })

      if (this.softSkillIdx !== 1) {
        this.removeNextClouds()
      }

      setTimeout(() => {
        let delayNum = 1

        const currentSkillDashes = this.getSkillDashes(this.softSkillIdx)

        Object.entries(currentSkillDashes).forEach(([i, dash]) => {
          if (dash.interlude) {
            delayNum += 8
          }
          else {
            delayNum++
          }
          dash.visible = true
          dash.extraClass = 'animated fadeIn delay' + delayNum
        })

        this.animateShip(this.softSkillIdx)
      }, 2000)
    }
  },
  methods: {
    endMapScreen(duration) {
      setTimeout(() => {
        this.$parent.routeGame()
      }, duration)
    },

    getSkillDashes(softSkillIdx) {
      return this.dashesDB({ skill: softSkillIdx }).get()
    },

    removeNextClouds() {
      Object.entries(this.clouds).forEach(([key, cloud]) => {
        if (key < this.cloudsPerIsland) {
          cloud.extraClass = 'animated bounceOut'
          cloud.visible = false
        }
      })
    },

    animateShip(softSkillIdx, index) {
      index = index || 0

      const shipElem = document.getElementById('ship')
      const currentSkillDashes = this.getSkillDashes(softSkillIdx)
      const dash = currentSkillDashes[index]

      this.ship.backwards = dash.backwards
      this.ship.left = dash.left - shipElem.offsetWidth / window.screen.width
      this.ship.top = dash.top - (shipElem.offsetHeight / window.screen.height) * 67

      if (dash.interlude) {
        this.removeNextClouds()
        setTimeout(() => {
          this.animateShip(softSkillIdx, index + 1)
        }, 2700)
      }
      else if (this.varExists(currentSkillDashes[index + 1])) {
        setTimeout(() => {
          this.animateShip(softSkillIdx, index + 1)
        }, 300)
      }
      else {
        this.ship.animated = false
        this.endMapScreen(1200)
      }
    }
  }
}
</script>
