module.exports = [
  { left: 0.5, top: 6, rotate: 4, skill: 0 },
  { left: 2.5, top: 6.2, rotate: 5, skill: 0 },
  { left: 4.6, top: 6.4, rotate: 5, skill: 0 },
  { left: 6.9, top: 6.7, rotate: 7, end: true, skill: 0 },
  { left: 21, top: 14.4, rotate: 20, end: true, skill: 1 },
  { left: 23.1, top: 16.1, rotate: 24, skill: 1 },
  { left: 25.2, top: 17.9, rotate: 32, skill: 1 },
  { left: 27, top: 19.8, rotate: 40, skill: 1 },
  { left: 28.5, top: 22.3, rotate: 50, skill: 1 },
  { left: 29.8, top: 25, rotate: 58, skill: 1 },
  { left: 31, top: 27.9, rotate: 58, skill: 1 },
  { left: 32.3, top: 31, rotate: 57, skill: 1 },
  { left: 33.7, top: 33.9, rotate: 48, skill: 1 },
  { left: 35.3, top: 36.4, rotate: 42, skill: 1 },
  { left: 37.2, top: 38.5, rotate: 32, interlude: true, skill: 1 },
  { left: 39.6, top: 39.1, rotate: -10, skill: 1 },
  { left: 41.5, top: 36.6, rotate: -78, skill: 1, backwards: true },
  { left: 41.7, top: 32.5, rotate: -88, skill: 1 },
  { left: 41.7, top: 28.5, rotate: -92, skill: 1, interlude: true },
  { left: 41.6, top: 24.6, rotate: -92, skill: 1 },
  { left: 42, top: 20.8, rotate: -62, skill: 1 },
  { left: 43, top: 17.6, rotate: -52, skill: 1 },
  { left: 44.5, top: 15.2, rotate: -32, end: true, skill: 1 },
  { left: 56.6, top: 15.9, rotate: 0, end: true, skill: 2 },
  { left: 58.8, top: 16.9, rotate: 9, skill: 2 },
  { left: 61.3, top: 17.8, rotate: 14, skill: 2 },
  { left: 63.5, top: 18.8, rotate: 15, skill: 2 },
  { left: 65.8, top: 19.6, rotate: 9, skill: 2 },
  { left: 68.1, top: 20.2, rotate: 7, skill: 2 },
  { left: 70.5, top: 20.6, rotate: 6, skill: 2 },
  { left: 72.6, top: 20.7, rotate: 2, skill: 2 },
  { left: 74.6, top: 20, rotate: -8, end: true, skill: 2 },
  { left: 78.2, top: 24.7, rotate: 86, end: true, skill: 3 },
  { left: 78.5, top: 28.8, rotate: 86, skill: 3, backwards: true },
  { left: 78.8, top: 32.8, rotate: 86, skill: 3, backwards: true },
  { left: 79.3, top: 36.3, rotate: 69, skill: 3, backwards: true },
  { left: 80.2, top: 39.4, rotate: 56, end: true, skill: 3, backwards: true },
  { left: 79.7, top: 53.1, rotate: 70, end: true, skill: 4, backwards: true },
  { left: 80.5, top: 57.1, rotate: 81, skill: 4, backwards: true },
  { left: 80.8, top: 60.5, rotate: 86, skill: 4, backwards: true },
  { left: 80.9, top: 64.2, rotate: 91, skill: 4, backwards: true },
  { left: 80.6, top: 67.9, rotate: 111, skill: 4, backwards: true },
  { left: 79.9, top: 71.5, rotate: 111, skill: 4, backwards: true },
  { left: 79.5, top: 75.2, rotate: 100, end: true, skill: 4, backwards: true },
  { left: 76.7, top: 86.9, rotate: -9, end: true, skill: 5, backwards: true },
  { left: 74.7, top: 87.5, rotate: 6, skill: 5, backwards: true },
  { left: 72.1, top: 87.2, rotate: 6, skill: 5, backwards: true },
  { left: 69.5, top: 87, rotate: 6, skill: 5, backwards: true },
  { left: 66.9, top: 86.7, rotate: 4, skill: 5, backwards: true },
  { left: 64.2, top: 86.5, rotate: 4, skill: 5, backwards: true },
  { left: 61.5, top: 86.4, rotate: 2, skill: 5, backwards: true },
  { left: 58.9, top: 86.6, rotate: -2, skill: 5, backwards: true },
  { left: 56.6, top: 87, rotate: -5, skill: 5, backwards: true },
  { left: 54.6, top: 87.4, rotate: -9, end: true, backwards: true, skill: 5 },
  { left: 41.9, top: 82.9, rotate: 4, end: true, backwards: true, skill: 6 },
  { left: 39.5, top: 82.4, rotate: 14, skill: 6, backwards: true },
  { left: 37.1, top: 81.7, rotate: 11, skill: 6, backwards: true },
  { left: 34.6, top: 81, rotate: 10, skill: 6, backwards: true },
  { left: 32, top: 80.4, rotate: 9, skill: 6, backwards: true },
  { left: 29.5, top: 79.7, rotate: 10, skill: 6, backwards: true },
  { left: 27.1, top: 79.2, rotate: 9, end: true, skill: 6, backwards: true },
  { left: 10.6, top: 75.2, rotate: 58, end: true, skill: 7, backwards: true },
  { left: 9.2, top: 71.9, rotate: 52, skill: 7, backwards: true },
  { left: 7.6, top: 68.6, rotate: 48, skill: 7, backwards: true },
  { left: 6, top: 65.6, rotate: 46, skill: 7, backwards: true },
  { left: 4.2, top: 62.2, rotate: 48, skill: 7, backwards: true },
  { left: 2.9, top: 58.8, rotate: 68, skill: 7, backwards: true },
  { left: 2.7, top: 54.8, rotate: 90, skill: 7, backwards: true },
  { left: 3.6, top: 51.1, rotate: 142, skill: 7, backwards: true },
  { left: 6, top: 49, rotate: 167, skill: 7, backwards: true },
  { left: 8.2, top: 48.2, rotate: -10, end: true, skill: 7 },
  { left: 25, top: 41, rotate: 3, end: true, skill: 8 },
  { left: 28, top: 41, rotate: 5, skill: 8 },
  { left: 31, top: 42, rotate: 7, skill: 8 },
  { left: 34, top: 43, rotate: 9, skill: 8 },
  { left: 37, top: 44, rotate: 9, skill: 8 },
  { left: 39, top: 46, rotate: 50, skill: 8 },
  { left: 40.5, top: 48.2, rotate: -10, end: true, skill: 8 },
  { left: 42.5, top: 53.2, rotate: 30, end: true, skill: 9 },
  { left: 44.5, top: 54, rotate: 0, skill: 9 },
  { left: 46.4, top: 55, rotate: 30, skill: 9 },
  { left: 47.6, top: 57.4, rotate: 45, end: true, skill: 9 },
  { left: 51.6, top: 56.4, rotate: -45, end: true, skill: 10 },
  { left: 52.5, top: 53.6, rotate: -70, skill: 10 },
  { left: 51.2, top: 52.8, rotate: -30, skill: 10 },
  { left: 49.3, top: 52.8, rotate: 30, skill: 10 },
  { left: 48.3, top: 50.3, rotate: 85, end: true, skill: 10 }
]
