var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !["chance", "judgement", "solace", "Void"].includes(_vm.name)
    ? _c("div", { staticClass: "island-box", style: _vm.backgroundImage })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }