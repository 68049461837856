<template>
  <div
    v-if="!['chance', 'judgement', 'solace', 'Void'].includes(name)"
    class="island-box"
    :style="backgroundImage"
  />
</template>

<script>
export default {
  name: 'Island',
  props: {
    name: {
      type: String,
      required: true
    },
    status: {
      type: String,
      default: '',
      validator: val => {
        if (val === 'completed' || val === 'pending') {
          return true
        }
      }
    }
  },
  computed: {
    islandBackgroundImage() {
      if (['chance', 'judgement', 'solace', 'Void'].includes(this.name)) return
      if (this.status === 'completed') {
        return require(`@/assets/img/world_map/islands/with_treasure/${this.name}_island_treasure.png`)
      }

      return require(`@/assets/img/world_map/islands/with_label/${this.name}_island.png`)
    },

    backgroundImage() {
      if (['chance', 'judgement', 'solace', 'Void'].includes(this.name)) return
      return `background-image: url('${this.islandBackgroundImage}');`
    }
  }
}
</script>

<style lang="scss" scoped>
  .island-box {
    background-size: 86% auto;
    background-position: center 15%;
    background-repeat: no-repeat;
  }
</style>
