var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "map-container",
      class: _vm.isMobile ? "island" + _vm.softSkillIdx : "bg_screen",
      attrs: { id: "world-map-container" },
    },
    [
      _c(
        "div",
        { staticClass: "flex-container world-background flex flex-wrap" },
        [
          _vm._l(_vm.islands, function (island, index) {
            return _c("Island", {
              key: island.name,
              staticClass: "item",
              class: "island-order-" + (index + 1),
              attrs: {
                name: island.name,
                status: _vm.softSkillIdx > index ? "completed" : "pending",
              },
            })
          }),
          _c("div", {
            staticClass: "item island-vortex island-order-placeholder",
          }),
        ],
        2
      ),
      !_vm.isMobile
        ? _c("div", {
            staticClass: "piece infinite tossing",
            class: {
              animated: _vm.ship.animated,
              backwards: _vm.ship.backwards,
            },
            style: {
              top: _vm.ship.top + "%",
              left: _vm.ship.left + "%",
            },
            attrs: { id: "ship" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "clouds" },
        _vm._l(_vm.clouds, function (cloud) {
          return _c("div", {
            key: cloud.id,
            staticClass: "cloud",
            class: ["cloud" + cloud.num, cloud.extraClass],
          })
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "dashes" },
        _vm._l(_vm.dashes, function (dash) {
          return _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: dash.visible,
                expression: "dash.visible",
              },
            ],
            key: dash.id,
            staticClass: "dash",
            class: [
              dash.type,
              dash.backwards ? "backwards" : "",
              dash.extraClass,
            ],
            style: {
              top: dash.top + "%",
              left: dash.left + "%",
              "-ms-transform": "rotate(" + dash.rotate + "deg)",
              "-webkit-transform": "rotate(" + dash.rotate + "deg)",
              transform: "rotate(" + dash.rotate + "deg)",
            },
          })
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }